body {
	background: #e32d2d;
	color: #fff;
	text-selection: none;
	/*padding: 20px;*/
}

.App-header {
	display: flex;
	justify-content: center;
	/*padding: 20px;*/
	padding: 20px 20px 0;
}

.App-header h1 {
	flex-grow: 1;
	font-size: 20px;
	font-weight: 700;
	margin: 0;
}

.App-header .container {
	/*margin-left: auto;*/
}

.App-header button {
	/*background: rgba(255,255,255,0.1);*/
	border: 1px solid rgba(255,255,255,0.2);
	background: none;
	font-size: 11px;
	height: 24px;
	border-radius: 4px;
	font-weight: 600;

	text-transform: uppercase;
	color: rgba(255,255,255,0.6);

}

.grid {
/*	display: flex;
	flex-wrap: wrap;
	position: relative;
	margin: -5px;*/

  display: grid;
  grid-template-columns: repeat(6, 110px);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);

  overflow: scroll;
  padding: 20px;
}

.col {
	box-sizing: border-box;
	flex-basis: 16.666%;
    /*outline: 3px solid black;*/

    /*margin: 5px;*/
    /*width: 16%;*/
    /*padding: 1px;*/
    /*padding: 10px;*/

    -webkit-tap-highlight-color: transparent;
}

.col .inner {
	padding: 10px;


    font-size: 15px;
    line-height: 1.2em;
    font-weight: 600;

    border-radius: 8px;
    color: #fff;

    height: 100px;
	background: rgba(255,255,255,0.2);
}

.col:active .inner {

	background: rgba(0,0,0,0.2);
}


.unselected {
}

.selected .inner {
    background-color: #fff;
    color: #e32d2d;
    /*box-shadow: 0 5px 15px rgba(0,0,0,0.2);*/
}